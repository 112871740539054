<template>
    <v-row>
        <v-dialog v-model="dialog" scrollable>
            <v-card outlined max-width="575">
                <v-card-title class="text-h5">{{tituloDialog}} - {{dadosDialog}}</v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>...</th>
                                <th>...</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template v-if="rede">
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-row class="mb-n12">
                                <v-col class="pl-2 pr-1 pl-lg-3 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="px-1 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-bottom-sheet class="d-inline-flex d-sm-none" v-model="sheet">
                                    <v-sheet class="text-center" height="100%">
                                        <v-row class="pa-3">
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtinicio"
                                                    label="Data inicial"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtfim"
                                                    label="Data final"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="sheet = !sheet"
                                                >Cancelar</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="filtrar(0), sheet = !sheet"
                                                >Filtrar</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-bottom-sheet>
                                <v-col class="px-1 d-flex">
                                    <v-select
                                        class="d-inline-flex d-sm-none"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                        append-outer-icon="mdi-menu"
                                        @click:append-outer="sheet = !sheet"
                                    ></v-select>
                                    <v-select
                                        class="d-none d-sm-block"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                    ></v-select>
                                    <v-btn
                                        :disabled="carregando"
                                        class="mx-2 mt-1"
                                        color="primary"
                                        elevation="0"
                                        fab
                                        x-small
                                        @click="filtrar(0)"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <span
                        class="text-caption text--disabled pl-3"
                    >* Atualizado em: 10:52:34 14/12/2021</span>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Vendas realizadas">
                            <template>R$ 257.535,00</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Margem de vendas">
                            <template>17,72</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Ticket médio">
                            <template>R$ 22.519,19</template>
                        </CardDashboard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas realizadas x Meta diária por filial</v-card-title>
                    <v-card-text>
                        <GraficoBarDashboard
                            :dataSeries="[
                                {name: 'Valor vendido', data: ['115986.37', '158697.42', '85987.59', '60325.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Meta diária', data: ['118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :categories="['Matriz', 'Pitanga', 'São João do Ivaí', 'Castelo', 'Cândido de Abreu', 'Apucarana', 'Jardim Alegre', 'Manoel Ribas', 'Faxinal', 'Campo Mourão', 'Cianorte', 'Paranavaí', 'Telemaco Borba', 'Roncador', 'Guarapuava', 'Palmital', 'Ibiporã', 'São Mateus do Sul', 'São João do Triunfo', 'Pontal do Paraná', 'Mandaguari', 'Astorga', 'Cambé', 'Reserva', 'Rolândia', 'Maringá']"
                            titleY="R$"
                            :horizontal="false"
                            :money="true"
                            height="450"
                            :distributed="false"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas por prazo de pagamento</v-card-title>
                    <v-card-text>
                        <GraficoPieDashboard
                            :dataSeries="[1235, 825]"
                            :labels="['à vista', 'à prazo']"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas por forma de pagamento</v-card-title>
                    <v-card-text>
                        <GraficoPieDashboard
                            :dataSeries="[134, 238, 358, 58, 122]"
                            :labels="['Dinheiro', 'Cartão Débito', 'Cartão Crédito', 'Cheque', 'Depósito']"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Ticket médio x Clientes atendidos por filial</v-card-title>
                    <v-card-text>
                        <GraficoLineColumnDashboard
                            :dataSeries="[
                                {name: 'Ticket médio', type: 'line', data: ['489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Clientes atendidos', type: 'column', data: ['542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Margem', type: 'line', data: ['27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :labels="['Matriz', 'Pitanga', 'São João do Ivaí', 'Castelo', 'Cândido de Abreu', 'Apucarana', 'Jardim Alegre', 'Manoel Ribas', 'Faxinal', 'Campo Mourão', 'Cianorte', 'Paranavaí', 'Telemaco Borba', 'Roncador', 'Guarapuava', 'Palmital', 'Ibiporã', 'São Mateus do Sul', 'São João do Triunfo', 'Pontal do Paraná', 'Mandaguari', 'Astorga', 'Cambé', 'Reserva', 'Rolândia', 'Maringá']"
                            :titlesY="[
                                { title: { text: 'Ticket médio' } },
                                { title: { text: 'Clientes atendidos' } },
                                { opposite: true, title: { text: 'Margem' } },
                            ]"
                            :tooltipsY="[
                                { enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } },
                                { enabled: true, formatter: function (val) { return val.toFixed(0) } },
                                { enabled: true, formatter: function (val) { return val + '%' } },
                            ]"
                            height="450"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
        <template v-else>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text>
                        <v-form>
                            <v-row class="mb-n12">
                                <v-col class="pl-2 pr-1 pl-lg-3 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="px-1 d-none d-sm-block">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-bottom-sheet class="d-inline-flex d-sm-none" v-model="sheet">
                                    <v-sheet class="text-center" height="100%">
                                        <v-row class="pa-3">
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtinicio"
                                                    label="Data inicial"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <InputDatePicker
                                                    :disabled="carregando"
                                                    v-model="busca.dtfim"
                                                    label="Data final"
                                                    :outlined="true"
                                                    :dense="true"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="sheet = !sheet"
                                                >Cancelar</v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn
                                                    text
                                                    class="mt-n3"
                                                    color="primary"
                                                    @click="filtrar(0), sheet = !sheet"
                                                >Filtrar</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-bottom-sheet>
                                <v-col class="px-1 d-flex">
                                    <v-select
                                        class="d-inline-flex d-sm-none"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                        append-outer-icon="mdi-menu"
                                        @click:append-outer="sheet = !sheet"
                                    ></v-select>
                                    <v-select
                                        class="d-none d-sm-block"
                                        clearable
                                        :items="filiais.lista"
                                        item-text="filial"
                                        item-value="idfilial"
                                        label="Filial"
                                        :disabled="carregando"
                                        outlined
                                        dense
                                        v-model="busca.idfilial"
                                    ></v-select>
                                    <v-btn
                                        :disabled="carregando"
                                        class="mx-2 mt-1"
                                        color="primary"
                                        elevation="0"
                                        fab
                                        x-small
                                        @click="filtrar(0)"
                                    >
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <span
                        class="text-caption text--disabled pl-3"
                    >* Atualizado em: 10:52:34 14/12/2021</span>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Vendas realizadas">
                            <template>R$ 257.535,00</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Margem de vendas">
                            <template>17,72</template>
                        </CardDashboard>
                    </v-col>
                    <v-col>
                        <CardDashboard :loading="carregando" title="Ticket médio">
                            <template>R$ 22.519,19</template>
                        </CardDashboard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas realizadas x Meta diária por vendedor</v-card-title>
                    <v-card-text>
                        <GraficoBarDashboard
                            :dataSeries="[
                                {name: 'Valor vendido', data: ['8589.37', '3589.42', '5589.59', '8589.12', '7589.71'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Meta diária', data: ['7358.37', '8589.37', '5987.37', '6589.37', '4589.37'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :categories="['José', 'Maria', 'João', 'Lucas', 'Sônia']"
                            titleY="R$"
                            :horizontal="false"
                            :money="true"
                            height="350"
                            :distributed="false"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title class="d-inline-block col-12 text-truncate">Margem por vendedor</v-card-title>
                    <v-card-text>
                        <GraficoBarDashboard
                            :dataSeries="[
                                {name: 'Margem', data: ['542', '375', '677', '627', '425'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :categories="['José', 'Maria', 'João', 'Lucas', 'Sônia']"
                            titleY="%"
                            :horizontal="true"
                            palette="palette2"
                            :distributed="true"
                            height="350"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Ticket médio x Clientes atendidos por vendedor</v-card-title>
                    <v-card-text>
                        <GraficoLineColumnDashboard
                            :dataSeries="[
                                {name: 'Ticket médio', type: 'line', data: ['489.37', '382.42', '757.59', '257.12', '425.71'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Clientes atendidos', type: 'column', data: ['542', '375', '677', '627', '425'], id: ['2', '5', '7', '510', '635']},
                            ]"
                            :labels="['José', 'Maria', 'João', 'Lucas', 'Sônia']"
                            :titlesY="[
                                { title: { text: 'Ticket médio' } },
                                { opposite: true, title: { text: 'Clientes atendidos' } }
                            ]"
                            :tooltipsY="[
                                { enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } },
                                { enabled: true, formatter: function (val) { return val.toFixed(0) } },
                            ]"
                            height="400"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas por prazo de pagamento</v-card-title>
                    <v-card-text>
                        <GraficoPieDashboard
                            :dataSeries="[1235, 825]"
                            :labels="['à vista', 'à prazo']"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title
                        class="d-inline-block col-12 text-truncate"
                    >Vendas por forma de pagamento</v-card-title>
                    <v-card-text>
                        <GraficoPieDashboard
                            :dataSeries="[134, 238, 358, 58, 122]"
                            :labels="['Dinheiro', 'Cartão Débito', 'Cartão Crédito', 'Cheque', 'Depósito']"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import GraficoPieDashboard from "../Widgets/GraficoPieDashboard";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";

export default {
    components: {
        InputDatePicker,
        CardDashboard,
        GraficoBarDashboard,
        GraficoPieDashboard,
        GraficoLineColumnDashboard,
    },
    name: "FilialDashboardSolar",
    data: () => ({
        sheet: false,
        rede: true,
        dialog: false,
        tituloDialog: "",
        dadosDialog: {},
        carregando: false,
        filiais: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getDate()}`,
            idfilial: 0,
        },

        series: [44, 55, 13],
        chartOptions: {
            chart: {
                width: 380,
                type: "pie",
            },
            theme: {
                palette: "palette6",
            },
            labels: ["Informação 1", "Informação 2", "Informação 3"],
            responsive: [
                {
                    breakpoint: 450,
                    options: {
                        chart: {
                            width: 310,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        dialogConsulta(titulo, dados) {
            this.dialog = true;
            this.tituloDialog = titulo;
            this.dadosDialog = dados;
        },
        filtrar() {
            if (this.busca.idfilial == 0) {
                this.rede = true;
            } else {
                this.rede = false;
            }
        },
        listarEmpresas() {
            return axios
                .post(`${this.backendUrl}filial/listar`, {
                    limit: 9999,
                    offset: 0,
                    busca: null,
                })
                .then((res) => {
                    let rede = { idfilial: 0, filial: "Rede" };
                    this.filiais = res.data;
                    this.filiais.lista = this.filiais.lista.filter((v) => {
                        return (
                            v.idfilial != 6 &&
                            v.idfilial != 12 &&
                            v.idfilial != 22 &&
                            v.idfilial != 25 &&
                            v.idfilial != 29 &&
                            v.idfilial != 32 &&
                            v.idfilial != 33 &&
                            v.idfilial != 34 &&
                            v.idfilial != 37
                        );
                    });
                    this.filiais.lista.unshift(rede);
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
    },
    created() {
        this.listarEmpresas();
    },
};
</script>

<style scoped>
</style>